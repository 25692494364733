
.pages-center {
    display: flex;
    justify-content: center;
    align-items: center;

    .go-circle {
        width: 20px;
        height: 20px;
        background: #2DC079;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        line-height: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
}
